<template>
  <div class="col-md-12 py-2 px-1">
    <div class="col-md-12">
      <div class="box row align-items-center py-3">
        <div class="col-md-12">
          <p class="category">
            {{item.serviceName}} /
            <strong>{{item.categoryName}}</strong>
          </p>
        </div>
        <div class="col-md-12 mb-2" v-if="item.oldGoal.goalNet != item.newGoal.goalNet">
          <div class="row">
            <div class="col-md-5">
              <div class="coluna">
                <h4 class="mb-0">Meta Líquida</h4>
              </div>
            </div>
            <div class="col-md-5 text-center ml-auto">
              <div class="box_value coluna">
                <p class="mb-2">
                  De:
                  <strong>{{item.oldGoal.goalNet }}</strong>
                </p>
                <p class="mb-0">
                  Para:
                  <strong>{{item.newGoal.goalNet }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-2" v-if="item.oldGoal.goalVolume != item.newGoal.goalVolume">
          <div class="row">
            <div class="col-md-5">
              <div class="coluna">
                <h4 class="mb-0">Meta Volume</h4>
              </div>
            </div>
            <div class="col-md-5 text-center ml-auto">
              <div class="box_value coluna">
                <p class="mb-2">
                  De:
                  <strong>{{item.oldGoal.goalVolume }}</strong>
                </p>
                <p class="mb-0">
                  Para:
                  <strong>{{item.newGoal.goalVolume }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-2" v-if="item.oldGoal.goalCost != item.newGoal.goalCost">
          <div class="row">
            <div class="col-md-5">
              <div class="coluna">
                <h4 class="mb-0">Meta Custo</h4>
              </div>
            </div>
            <div class="col-md-5 text-center ml-auto">
              <div class="box_value coluna">
                <p class="mb-2">
                  De:
                  <strong>{{item.oldGoal.goalCost }}</strong>
                </p>
                <p class="mb-0">
                  Para:
                  <strong>{{item.newGoal.goalCost }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10 mx-auto d-flex justify-content-center align-items-center mb-2">
          <div class="rejeita solicita mx-2">
            <p
              @click.prevent="reject(item.newGoal.id ,item.tenantInformation.tenantId)"
              class="p_click"
            >
              <i class="far fa-times-circle mr-1"></i> Rejeitar
            </p>
          </div>
          <div class="aceita solicita mx-2">
            <p
              @click.prevent="aprova(item.newGoal.id ,item.tenantInformation.tenantId)"
              class="p_click"
            >
              Aceitar
              <i class="far fa-check-circle ml-1"></i>
            </p>
          </div>
        </div>
        <div class="col-md-10 mx-auto text-center">
          <p class="mb-0 unidade">{{item.tenantInformation.tenantName}}</p>
          <p class="mb-0" style="color: #868686;">{{item.tenantInformation.franchiseName}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["item"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    aprova(serviceId, tenantId) {
      HTTP.post(
        `services/app/ServiceGoal/AproveGoal?serviceGoalId=${serviceId}&tenantId=${tenantId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$nextTick(() => {
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>Solicitação aceita`
            });
            if (this.$route.path == "/") {
              this.$emit("getAll");
            }else{
              this.$emit("getList");
            }
          });
        })
        .catch(error => {
          this.load = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    },
    reject(serviceId, tenantId) {
      HTTP.post(
        `services/app/ServiceGoal/RejectGoal?serviceGoalId=${serviceId}&tenantId=${tenantId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$nextTick(() => {
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>Solicitação rejeitada`
            });
            if (this.$route.path == "/") {
              this.$emit("getAll");
            }else{
              this.$emit("getList");
            }
          });
        })
        .catch(error => {
          this.load = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  }
};
</script>

<style scoped>
i {
  font-size: 25px;
}
.solicita p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.solicita p:active {
  color: #868686 !important; /* links ativos */
}
.rejeita {
  color: #e67979;
}
.aceita {
  color: #2474b2;
}
.unidade {
  color: #2474b2;
}
.box_value {
  background: #175d93;
  border-radius: 20px;
}
.box_value p {
  color: #ffffff;
}
.coluna {
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
}
.category {
  font-size: 12px;
  color: #868686;
}
h4 {
  font-size: 12px;
  color: #2474b2;
}
.box {
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 20px;
  box-shadow: 0px 8px 8px 0 rgba(68, 68, 68, 0.3);
}
</style>