<template>
<div class="row mx-0 mt-4">
  <div v-show="!get_masterTenantCards.load" class="col-12 p-0">
    <div class="col d-flex flex-column-reverse flex-md-row justify-content-between borderBtm pl-0 pr-0">
      <p class="titulo_dash mb-0 mt-4 mt-md-0 ">Índices dos Franqueados</p>
      <div class="col-md-8 px-0 d-flex">
        <div class="col-md-8 ml-auto p-0 mt-1 my-auto ">
          <inputFilter @filterCp="filterCp" id="searchTenant" />
        </div>
        <div class="col-auto pr-0">
          <ButtonToggle :activo="activoCurrent" @toggleConvert="toggleBtn" />
        </div>
      </div>
    </div>
  </div>
  <div v-show="!get_masterTenantCards.load" class="col-12 borderBtm p-0">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="textUnid mb-2 mb-md-0">Unidades em Destaque</h2>
      <router-link to="/unidades" tag="div" v-show="!get_masterTenantCards.load">
        <h3 class="text-right allSubText">
          Todas {{ resultQuery.length }} Unidades
          <img src="@/assets/image/metas/arrow.svg" alt="arrow.svg" />
        </h3>
      </router-link>
    </div>
  </div>
  <div v-if="!get_masterTenantCards.load && resultQuery.length < 1" class="m-auto">
    <h1 class="notUnid">
      Nenhuma Unidade Encontrada
    </h1>
  </div>
  <div v-if="!get_masterTenantCards.load && resultQuery.length > 0" :class="
        resultQuery.length > 4
          ? 'col-12 p-0 d-flex align-items-center position-relative leftPosition teste'
          : 'col-12 p-0 d-flex align-items-center teste'
      ">
    <button @click="showPrev" class="btnArrow prev col" v-if="resultQuery.length > 4">
      <img src="@/assets/image/carousel/arrow.svg" alt="arrow" />
    </button>
    <VueSlickCarousel :class="
          shadowOpen ? 'col p-0 sliceMaster shadowOpen' : 'col p-0 sliceMaster'
        " v-bind="settings" v-if="resultQuery.length > 0" ref="carousel" :key="carouselKey">
      <boxUnidade class="itens list-complete-item " v-for="(item, index) in resultQuery" :key="index + 1" :item="item" :porceto="activoCurrent" @edit="showModalEdit" @enterTenant="enterTenant" />
    </VueSlickCarousel>
    <button @click="showNext" class="btnArrow next col" v-if="resultQuery.length > 4">
      <img src="@/assets/image/carousel/arrow.svg" alt="arrow" />
    </button>
    <div class="btnLinkMobile">
      <router-link to="/unidades" tag="div">
        <h3 class="text-right btnLink">
          Todas {{ resultQuery.length }} Unidades
          <img src="@/assets/image/metas/arrow.svg" alt="arrow.svg" />
        </h3>
      </router-link>
    </div>

  </div>
  <div v-if="get_masterTenantCards.load" class="d-flex align-items-center justify-content-center w-100" style="height:250px;">
    <half-circle-spinner :animation-duration="1000" :size="100" color="#fff" />
  </div>
  <div class="col-md-12 mt-5 mx-auto mt-md-3 row">
    <div class="thumbnail-barchart" v-if="!get_masterGraphicColumn.load">
      <BarChart :dataCurrent="get_GraphicColumnCurrent" :dataOldNetProfit="get_GraphicColumnOldCurrent" :months="get_GraphicColumnMonths" :year.sync="year" :lastYear.sync="lastYear" />
    </div>
    <div v-else class="m-auto" style="height:250px;">
      <half-circle-spinner :animation-duration="1000" :size="100" color="#fff" />
    </div>
    <div class="thumbnail-piechart classMobileShow" v-if="!get_masterGraphicServiceSold.load">
      <PieChart :dataValuePie="dataValuePie" :labelServicePieNameFull.sync="labelServicePieNameFull" :activoPieCurrent.sync="activoPieCurrent" />
    </div>
    <div v-else class="m-auto" style="height:250px;">
      <half-circle-spinner :animation-duration="1000" :size="100" color="#fff" />
    </div>
  </div>
  <component :is="cp" :listUser="listUser" :id.sync="idModal" :tenantDisableds="get_tenantDisableds" :tenantsActives="get_tenantInformations" @closeModal="closeModal" @getUnidades="getAll" />
</div>
</template>

<script>
import inputFilter from "@/components/filters/inputFilter.vue";
import boxUnidade from "./components/box";
import solicitacao from "./components/solicitacao";
import {
  HalfCircleSpinner
} from "epic-spinners";
import ButtonToggle from "@/components/metas/buttons/button_toggle.vue";
import BarChart from "./components/BarChart.vue";
import PieChart from "./components/Pie.vue";
import ModalEdit from "@/components/adm/unidades/modal/modalUnidade.vue";
import HTTP from "@/api_system";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import {
  mapGetters
} from "vuex";

export default {
  components: {
    inputFilter,
    boxUnidade,
    solicitacao,
    HalfCircleSpinner,
    ButtonToggle,
    PieChart,
    BarChart,
    ModalEdit,
    VueSlickCarousel: () => import("vue-slick-carousel")
  },
  data() {
    return {
      filter: null,
      metas: [],
      activoCurrent: "Percent",
      activoPieCurrent: "money",
      settings: {
        arrows: false,
        dots: false,
        infinite: false,
        centerMode: false,
        centerPadding: "5px",
        initialSlide: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        "responsive": [{
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 2,
              "initialSlide": 1,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]

        // this.getLoad(this.get_masterTenantCards.load) ||
        // this.resultQuery.length > 3
        //   ? 4
        //   : this.resultQuery.length,

        // this.getLoad(this.get_masterTenantCards.load) ||
        // this.resultQuery.length > 3
        //   ? 4
        //   : this.resultQuery.length
      },
      carouselKey: 1,
      datacollection: null,
      dataCurrent: [],
      dataOldNetProfit: [],
      shadowOpen: false,
      idModal: "",
      listUser: "",
      cp: "",
      dataValuePie: [],
      labelServicePieNameFull: [],
      year: undefined,
      lastYear: undefined
    };
  },
  mounted() {
    this.getAll();
    this.allPerson();
    this.getServiceType();
  },
  methods: {
    async getAll() {
      try {
        await this.$store.dispatch("GetDashboardMaster");
        await this.$store.dispatch("GetDashboardMasterGraphicColumn", {
          date: "",
          tenantId: ""
        });
        await this.$store.dispatch("GetDashboardServicesSold", {
          date: "",
          tenantId: "",
          categoryId: ""
        });
        await this.$store.dispatch("getAllTenants");
      } catch (error) {
        this.toast("Ops algo deu errado", this.$toast.error);
        console.log(error);
      }
    },
    allPerson() {
      this.$store.dispatch("getPessoas").then(resolve => {
        this.$nextTick(() => {
          let array = [];
          resolve.forEach(item => {
            if (item.profile == 2) {
              array.push({
                userId: item.id,
                displayName: item.displayName
              });
            }
          });
          this.listUser = array;
        });
      });
    },
    getServiceType() {
      this.$store.dispatch("getServiceType");
    },
    showModalEdit(params) {
      this.idModal = params.id;
      this.cp = "ModalEdit";
      this.$nextTick(() => {
        $("#modalUnidade").modal("show");
      });
    },
    closeModal() {
      $(`#modalUnidade`).modal("hide");
      this.cp = "";
    },
    enterTenant(params) {
      HTTP.post(
          `TokenAuth/AuthenticateFromMaster?tenantId=${params.id}&userId=${this.getUser.id}`,
          "", {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        )
        .then(response => {
          this.$store.commit(
            "set_originalToken",
            this.$store.getters.getterToken
          );
          this.$store.commit(
            "set_loginClone",
            this.$store.state.login.userLogin
          );
          this.$store.commit(
            "set_tenantClone",
            this.$store.state.tenent.tenant
          );
          this.$nextTick(() => {
            this.$store.commit("set_token", response.data.result.accessToken);
            this.$store.commit("set_user", response.data.result.informations);
            this.$store.commit("set_tenant", response.data.result.tenant);

            this.$router.push("/");
            this.$store.dispatch("getAlert");
            this.$store.dispatch("getAlertImportant");
            this.toast(`Unidade ${params.name} Selecionada!`, this.$toast);
          });
          window.location.reload();
        })
        .catch(error => {
          this.toast("Ops algo deu errado", this.$toast.error);
        });
    },
    changeShowShadow(value) {
      this.shadowOpen = value;
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    async toggleBtn(changeValue) {
      console.log(changeValue);
      let param = changeValue == "Percent" ? undefined : true;
      await this.$store.dispatch("GetDashboardMaster", param);
      this.activoCurrent = changeValue;
    },
    filterCp(evt) {
      this.filter = `${evt.target.value}`;
    },
    retryCarosel() {
      this.carouselKey += 1;
    }
  },
  computed: {
    resultQuery() {
      if (this.filter) {
        return this.get_tenantInformations.filter(item => {
          return this.filter
            .toLowerCase()
            .split(" ")
            .every(
              v =>
              item.name.toLowerCase().includes(v) ||
              item.tenancyName.toLowerCase().includes(v)
            );
        });
      } else {
        return this.get_tenantInformations;
      }
    },
    get_tenantInformations() {
      if (
        this.get_masterTenantCards.result &&
        this.get_masterTenantCards.result.tenantInformations
      ) {
        return this.get_masterTenantCards.result.tenantInformations;
      }
      return [];
    },
    get_tenantDisableds() {
      if (
        this.get_masterTenantCards.result &&
        this.get_masterTenantCards.result.tenantsDisableds
      ) {
        return this.get_masterTenantCards.result.tenantsDisableds;
      }
      return [];
    },
    get_GraphicColumnCurrent() {
      if (
        JSON.stringify(this.get_masterGraphicColumn.result) != "{}" &&
        this.get_masterGraphicColumn.result.currentGrossProfit.length
      ) {
        return this.get_masterGraphicColumn.result.currentGrossProfit.map(
          t => t.value
        );
      }
      return [];
    },
    get_GraphicColumnOldCurrent() {
      if (
        JSON.stringify(this.get_masterGraphicColumn.result) != "{}" &&
        this.get_masterGraphicColumn.result.oldGrossProfit.length
      ) {
        return this.get_masterGraphicColumn.result.oldGrossProfit.map(
          t => t.value
        );
      }
      return [];
    },
    get_GraphicColumnMonths() {
      if (
        JSON.stringify(this.get_masterGraphicColumn.result) != "{}" &&
        this.get_masterGraphicColumn.result.months.length
      ) {
        return this.get_masterGraphicColumn.result.months;
      }
      return [];
    },
    get_GraphicServiceSold() {
      return this.get_masterGraphicServiceSold.result;
    },
    ...mapGetters([
      "get_masterTenantCards",
      "get_masterGraphicColumn",
      "get_masterGraphicServiceSold",
      "getUser"
    ])
  },
  watch: {
    resultQuery() {
      this.retryCarosel();
    },
    "get_masterTenantCards.error"() {
      if (this.get_masterTenantCards.error) {
        this.toast("Ops, erro ao carregar as unidades", this.$toast.error);
      }
    },
    "get_masterGraphicServiceSold.error"() {
      if (this.get_masterGraphicServiceSold.error) {
        this.toast(
          "Ops, erro ao carregar os servicos mais vendidos",
          this.$toast.error
        );
      }
    },
    "get_masterGraphicColumn.error"() {
      if (this.get_masterGraphicColumn.error) {
        this.toast(
          "Ops, erro ao carregar os dados do grafico de crescimento",
          this.$toast.error
        );
      }
    },
    get_masterGraphicServiceSold() {
      this.labelServicePieNameFull = [];
      this.dataValuePie = [];
      if (
        this.get_masterGraphicServiceSold &&
        this.get_masterGraphicServiceSold.result.length
      ) {
        // let index = 1;
        this.get_masterGraphicServiceSold.result.forEach(item => {
          let fullServicePieName = `${item.serviceName} - ${item.valueSoldReal}`;
          this.labelServicePieNameFull.push(fullServicePieName);
          this.dataValuePie.push(item.percentSold);
        });
      }
    },
    activoPieCurrent() {
      this.labelServicePieNameFull = [];
      this.dataValuePie = [];
      // let index = 1;
      this.get_masterGraphicServiceSold.result.forEach(item => {
        let fullServicePieName = `${item.serviceName} - ${
          this.activoPieCurrent == "Percent"
            ? item.quantitySold
            : item.valueSoldReal
        }`;
        this.labelServicePieNameFull.push(fullServicePieName);
        this.dataValuePie.push(item.percentSold);
      });
    }
  }
};
</script>

<style scoped>
.over-scroll {
  cursor: pointer !important;
}

.list-complete-item {
  transition: all 1s !important;
  width: 95% !important;
}

.vertical-enter-active {
  transition: all 1s !important;
}

.titulo_dash {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
}

.borderBtm {
  border-bottom: 1px solid #3f88c1;
  padding-bottom: 5px;
  margin-bottom: 25px;
}

.textUnid {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.allSubText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #98c4e6;
  cursor: pointer;
  display: none;
}

.btnLink {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #98c4e6;
  cursor: pointer;
}

.btnLinkMobile {
  position: absolute;
  bottom: -40px;
  right: 0;
}

.btnArrow {
  border: none;
  background: #206aad;
  /* opacity: 0.5; */
  border-radius: 0px 8px 8px 0px;
  width: 32px;
  height: 86px;
  outline: none;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnArrow:hover {
  background: #1b60a8;
}

.prev {
  transform: rotate(180deg);
}

.containerScrool {
  max-width: 1000px;
  overflow: auto;
}

.scrool {
  height: 30px;
  width: 1200px;
  background: #1b60a8;
}

.slick-list {
  overflow: overlay !important;
  height: 365px !important;
}

.leftPosition {
  left: -32px;
}

.shadowOpen:before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.253);
  content: "";
  z-index: 1;
}

.notUnid {
  font-size: 25px;
  text-align: center;
  margin: 40px;
  color: #ffffff8c;
}

.thumbnail-barchart {
  width: 60%;
  margin-bottom: 7px;
}

.thumbnail-piechart {
  width: 39%;
  margin-left: 7px;
  margin-bottom: 7px;
}

.teste {
  position: relative;
}

@media (min-width: 768px) {
  .allSubText {
    display: block;
  }

  .btnLinkMobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .thumbnail-piechart {
    width: 100%;
    margin-left: 0px;
  }

  .thumbnail-barchart {
    width: 100%;
  }

}
</style>
